<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="mt-28">
    <label for="comment" class="block text-xl font-medium text-gray-900">{{loc.transactions}}</label>
    <p class="text-sm mt-5">{{ loc.transactions_desc }}</p>

    <div class="lg:flex items-center gap-4 space-y-2 lg:space-y-0 mt-5 lg:mt-10 text-sm">
      <div class="w-full lg:w-4/12 flex items-center justify-between gap-4">
        <div class="w-1/2">
          <button class="w-full focus:ring-2 ring-base-500 rounded-md">
            <datepicker
                class="w-full"
                v-model="startDate" name="1"
                calendar-class="shadow-xl"
                iconColor="#d1d5db"
                :clear-button="true"
                :monday-first="true"
                :placeholder="loc.date_from"
            >
              <template v-if="!startDate" v-slot:customCalendarHeader>
                <div class="h-24 w-full bg-base-600 text-white text-lg rounded-t-lg font-medium flex items-center justify-center">
                  {{ loc.date_from }}
                </div>
              </template>
            </datepicker>
          </button>
        </div>
        <div class="w-1/2">
          <button class="w-full focus:ring-2 ring-base-500 rounded-md">
            <datepicker
                class="w-full"
                v-model="endDate" name="1"
                calendar-class="shadow-xl transform -translate-x-40 md:-translate-x-6"
                iconColor="#d1d5db"
                :disabled-dates="{to: startDate}"
                :clear-button="true"
                :monday-first="true"
                :placeholder="loc.date_to"
            >
              <template v-if="!endDate" v-slot:customCalendarHeader>
                <div class="h-24 w-full bg-base-600 text-white text-lg rounded-t-lg font-medium flex items-center justify-center">
                  {{ loc.date_to }}
                </div>
              </template>
            </datepicker>
          </button>
        </div>
      </div>
      <div class="w-12/12 lg:w-5/12">
<!--        <label for="search" class="block text-sm text-gray-700">{{ loc.id_or_provider_name }}</label>-->
        <div class="relative flex items-center">
          <div class="absolute inset-y-0 left-0 flex items-center justify-center pl-3.5">
            <search-icon class="text-gray-600 h-5 w-5"/>
          </div>
          <input v-model.lazy="search" v-debounce="400" type="text" name="search" id="search" :placeholder="loc.id_or_provider_name"
                 class="pl-10 w-full shadow-sm  h-10 focus:ring-base-500 focus:border-base-500 block sm:text-sm border-gray-300 rounded-md"/>
        </div>
      </div>
      <div class="flex items-center w-full lg:w-3/12 gap-x-4">
        <div class="w-1/2 lg:w-4/6">
          <Menu as="div" class="relative p-0 m-0 z-30">
            <!--            <label for="location" class="block text-sm text-gray-700">{{ loc.status }}</label>-->
            <MenuButton class="focus:ring-2 ring-base-500 rounded-md shadow border w-full h-10 bg-white flex items-center justify-between text-gray-500 hover:text-base-600 relative px-2">
              <p class="w-5/6 truncate text-left text-base" :class="selectedStatus?.name ? 'text-black' : ''">{{ selectedStatus?.name ? parse_name(selectedStatus?.name) : loc.status }}</p>
              <font-awesome-icon v-if="selectedStatus.name" :icon="['far', 'xmark-circle']" @click.stop="setSelectedStatus('')" class="relative z-20 text-red-600 mr-1.5" />
              <font-awesome-icon  icon="fa-chevron-down" :class="$refs['statuses']?.visible ? 'transform -rotate-180 transition duration-200 linear' : 'transition duration-200 linear'" />
            </MenuButton>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems ref="statuses" class="origin-top-right absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 h-32 overflow-y-hidden">
                <div class="py-1">
                  <MenuItem v-slot="{ active }" v-for="status in statusOptions" @click="setSelectedStatus(status)">
                    <div class="flex px-2 py-2 border-b text-sm cursor-pointer hover:bg-gray-200 hover:text-base-600" :class="selectedStatus.value === status.value ? 'bg-base-600 text-white font-medium': ''">
                      {{ parse_name(status.name) }}
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="w-1/2 lg:w-2/6">
          <button @click="exportTransactions" :disabled="loadingDownload" class="relative transform lg:-translate-x-1 flex items-center justify-center gap-x-1 w-full h-10 text-white bg-base-600 hover:bg-base-500 font-medium rounded-md tracking-tight">
            <font-awesome-icon  icon="fa-download" :class="loadingDownload ? 'animate-bounce' : ''" />
            <p class="transform scale-110">Export</p>
          </button>
        </div>
      </div>
<!--      <div class="col-span-2">-->
<!--&lt;!&ndash;        <p class="sr-only">placehholder</p>&ndash;&gt;-->
<!--        <button @click="filter" class="w-full bg-base-600 text-white font-medium rounded h-10 hover:bg-base-700 transition duration-200 ease-in-out">-->
<!--          {{ loc.apply_filters }}-->
<!--        </button>-->
<!--      </div>-->
    </div>


    <div class="mt-8 flex flex-col">
      <div class="border rounded-lg overflow-x-auto">
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <div class="min-w-full divide-y-2 divide-white">
              <div class="relative h-screen-scroll overflow-x-hidden box-border">
                <div class="w-full bg-base-600 sticky top-0 z-20">
                  <div class="flex w-full text-white divide-x-2 divide-white box-border" :class="expanded ? 'border border-transparent box-border' : ''">
                    <div class="w-24 md:w-2/12 px-3 py-3.5 text-left text-sm font-medium">ID</div>
                    <div class="w-32 md:w-2/12 px-3 py-3.5 text-left text-sm font-medium">{{ loc.date }}</div>
                    <div class="w-64 md:w-4/12 px-3 py-3.5 text-left text-sm font-medium">{{loc.benefit_provider}}</div>
                    <div class="w-24 md:w-2/12 px-3 py-3.5 text-left text-sm font-medium">{{loc.invoices}}</div>
                    <div class="w-44 md:w-2/12 px-3 py-3.5 text-left text-sm font-medium">{{loc.total_price}}</div>
                    <div class="w-24 md:w-1/12 px-3 py-3.5 text-center text-sm font-medium ">
                      {{loc.status}}
                    </div>
                  </div>
                </div>
                <div v-if="transactions.results && !loading" class="divide-y-2 divide-white bg-gray-100 select-none box-border">
                  <div v-for="transaction in transactions.results" :key="transaction.id" class="hover:bg-gray-50 box-border">
                    <div class="cursor-pointer flex divide-x-2 divide-white box-border" @click="toggle_expanded(transaction.id)" :class="transaction.id === expanded ? 'border border-base-600 border-b-0 animation duration-500 ease-in-out' : 'border-r border-l border-transparent'">
                      <div :class="`w-24 md:w-2/12 px-3 py-3.5 text-left text-sm font-medium text-gray-900`">{{ transaction.id }}</div>
                      <div class="w-32 md:w-2/12 px-3 py-3.5 text-left font-medium whitespace-nowrap truncate text-sm text-gray-600">{{ formatDate(transaction.created_at, 'DD.MM.YYYY.') }}</div>
                      <div class="w-64 md:w-4/12 text-left whitespace-nowrap truncate px-3 py-3.5 text-sm text-gray-600">
                        <div v-for="provider in transaction.providers">
                          <p>{{provider}}</p>
                        </div>
                      </div>
                      <div class="w-24 md:w-2/12 whitespace-nowrap truncate px-3 py-3.5 flex justify-start items-center text-sm text-center text-gray-600">
                        <div v-for="invoice in transaction.invoice">
                          <a :href="invoice.pdf" target="_blank">
                            <DocumentIcon class="w-8 h-8 hover:text-base-500 text-center" />
                          </a>
                        </div>
                      </div>
                      <div class="flex items-center truncate w-44 md:w-2/12 text-sm text-gray-600 px-3">
                        {{transaction.price}}
                      </div>
                      <div class="group w-24 md:w-1/12 whitespace-nowrap flex justify-center items-center px-3 py-3.5 text-sm text-gray-600 relative">
                        <div class="tooltip">
                          <ClockIcon v-if="!transaction.finished && !transaction.canceled" class="w-9 h-9 text-yellow-600"/>
                          <div v-else-if="transaction.finished && transaction.canceled" class="border border-red-600 box-border rounded-full flex items-center justify-center">
                            <font-awesome-icon  icon="fa-circle-xmark" class="text-red-600 h-7 w-7" />
                          </div>
                          <CheckCircleIcon v-else class="w-9 h-9 text-green-600"/>
                          <p class="tooltiptext ">{{transaction.finished && !transaction.canceled ? loc.completed : loc.not_completed}}</p>
                          <p v-if="transaction.finished && transaction.canceled" class="tooltiptext">{{ loc.cancelled }}</p>
<!--                          <span class="hidden group-hover:flex">{{transaction.finished? loc.completed : loc.not_completed}}</span>-->
                        </div>
                      </div>
                    </div>

                    <div style="border-top: 1px solid white" :class="transaction.id === expanded ? 'border border-base-600 box-border border-t-0 transition duration-500 ease-in-out transform translate-y-0 opacity-100' : 'transform opacity-0 -translate-y-5'">
                      <div v-if="transaction.id === expanded" class="">
                        <div v-for="item in transaction.benefits_string" class="flex items-center text-sm divide-x-2 divide-white">
                          <div class="w-24 md:w-2/12 whitespace-nowrap truncate py-3.5 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                            <br>
                            <br>
                          </div>
                          <div class="w-32 md:w-2/12 whitespace-nowrap truncate py-3.5 px-3 text-sm text-gray-900 sm:pl-3">
                            <label class="font-medium">{{ loc.time }}</label>
                            <p>{{ formatDate(transaction.created_at, 'HH:mm') }}</p>
                          </div>
                          <div class="w-64 md:w-4/12 whitespace-nowrap truncate py-3.5 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                            <label class="font-medium">{{ loc.name }}</label>
                            <p class="text-base-600">{{ item.name }}</p>
                          </div>
                          <div class="w-24 md:w-2/12 whitespace-nowrap truncate py-3.5 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                            <label class="font-medium">{{ loc.quantity }}</label>
                            <p class="text-base-600">{{ item.count }}</p>
                          </div>
                          <div class="w-44 md:w-2/12 whitespace-nowrap truncate py-3.5 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                            <label class="font-medium">{{ loc.price }}</label>
                            <p class="text-base-600">{{ item.price }}</p>
                          </div>
                          <div class="w-24 md:w-1/12 whitespace-nowrap truncate py-3.5 px-3 text-sm font-medium text-gray-900">
                            <br>
                            <br>
                          </div>
                        </div>
                      </div>
                      <!--                    <p class="text-sm text-gray-600">-->
                      <!--                      {{loc.flexible-benefits}} : <span v-for="item in transaction.benefits_string">{{item.name}} x {{item.count}} ({{item.price}})</span>-->
                      <!--                    </p>-->
                    </div>
                  </div>
                </div>
                <div v-if="loading" class="h-screen-loader">
                  <ElementLoader />
                </div>
                <div v-if="transactions && transactions?.results?.length === 0" class="py-40 font-medium text-sm flex items-center justify-center">
                  <div class="space-y-4 text-center">
                    <font-awesome-icon  icon="fa-list-check" class="text-4xl" />
                    <p>{{ loading ? loc.loading : loc.no_transactions }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination
        v-if="transactions?.results?.length"
        :disabled="loading"
        :per-page="20"
        :total-items="transactions.count"
        @onPrevious="fetch('previous')"
        @onNext="fetch('next')"
    />
  </div>
  <error-notification :message="error" :show="!!error" />
</template>

<script>
import {SearchIcon, DocumentIcon, ClockIcon, CheckCircleIcon} from "@heroicons/vue/solid";
import Pagination from "@/components/pagination.vue";
import dayjs from 'dayjs';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import ElementLoader from "../components/ui/elementLoader.vue";
import errorNotification from "@/components/ui/ErrorNotification.vue";


export default {
  components: {
    errorNotification,
    ElementLoader,
    Pagination,
    DocumentIcon,
    SearchIcon,
    ClockIcon,
    CheckCircleIcon,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
  },
  data() {
    return {
      transactions: [],
      search: '',
      expanded: null,
      finishedFilter: false,
      selectedStatus: '',
      startDate: null,
      error: null,
      endDate: null,
      loading: false,
      loadingDownload: false,
      statusOptions: [
        // { name: 'All / Sve', value: 'all' },
        { name: 'Completed / Završeno', value: 'finished' },
        { name: 'In Process / U procesu', value: 'in_progress' },
        { name: 'Canceled / Stornirano', value: 'cancelled' },
      ]
    }
  },
  watch: {
    error(val) {
      if (val) {
        setTimeout(() => {
          this.error = null;
        }, 4000);
      }
    },
    // search: async function (val) {
      // await this.fetch()
    // },
    finishedFilter: function (val) {
      this.fetch()
    },
    startDate() {
      this.fetch();
    },
    endDate() {
      this.fetch();
    },
    selectedStatus() {
      this.fetch();
    },
    search() {
      this.fetch();
    },
  },
  methods: {
    async exportTransactions() {
      try {
        this.loadingDownload = true;
        const response = await this.axios.get('/v2/transactions/export/', {
          responseType: 'arraybuffer',
          timeout: 180000
        });
        console.log(response, 'RESPONSE')

        const blob = new Blob([new Uint8Array(response.data)], { type: response.headers['content-type'] });
        // const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;

        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'transactions.xlsx';


        // if (contentDisposition) {
        //   const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        //   if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        // }

        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        this.loadingDownload = false;
      } catch (e) {
        this.loadingDownload = false;
        this.error = this.loc.something_went_wrong;
      }
    },
    formatDate(date, format) {
      return dayjs(date).format(format);
    },
    resetFilters() {
      this.transactions.results = [];
    },
    statusHandler(status) {
      console.log(status, 'status')
      switch (status) {
        case 'finished':
          return `&finished=true&canceled=false`;
        case 'in_progress':
          return '&finished=false&canceled=false';
        case 'cancelled':
          return '&canceled=true';
        default:
          return ''
      }
    },
    async fetch(direction = '') {
      this.loading = true;
      let url = `v2/transactions/?search=${this.search}`;

      if (this.startDate) {
        url += `&created_from=${this.formatDate(this.startDate, 'YYYY-MM-DD')}`;
      }

      if (this.endDate) {
        url += `&created_to=${this.formatDate(this.endDate, 'YYYY-MM-DD')}`;
      }

      if (this.selectedStatus) {
        url += this.statusHandler(this.selectedStatus.value);
      }

      if (this.search || this.startDate || this.endDate || this.selectedStatus) this.resetFilters();

      if (direction === 'previous') {
        url = this.transactions.previous;
      }

      if (direction === 'next') {
        url = this.transactions.next;
      }

      try {
        let {data} = await this.axios.get(url);
        // this.transactions = data;
        this.transactions = data;
        this.loading = false;
      } catch (e) {
        console.log('No data!')
        this.loading = false;
      }
    },
    toggle_expanded(transaction_id) {
      if (this.expanded === transaction_id) {
        this.expanded = 0
      } else {
        this.expanded = transaction_id
      }
    },
    setSelectedStatus(status) {
      this.selectedStatus = status;
    },
    parse_name(name) {
      let srb = '';
      let eng = '';
      if (name.includes('/')) {
        eng = name.split('/')[0];
        srb = name.split('/')[1];
        if (this.$store.state.auth.user.language == "SR") {
          return srb;
        } else {
          return eng;
        }
      }
      return name;
    },
  },
  async mounted() {
    await  this.fetch()
  },
}
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 90px !important;
  height: 22px;
  background-color: #046c9c !important;
  color: #fff !important;
  text-align: center;
  border-radius: 6px;
  padding: 3px 0;

  /* Position the tooltip */
  position: absolute;
  top: -20px;
  left: -30px;
  z-index: 1000 !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.h-screen-scroll {
  max-height: calc(100vh - 406px);
  overflow-y: auto;
}

.h-screen-loader {
  height: calc(100vh - 479px);
  overflow: hidden;
}

.dp__action_row {
  width: 295px !important;
  border: 1px solid red !important;
}

</style>